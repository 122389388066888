import Decimal from 'decimal.js';
type CommonOptions = {
  // thousandSeparator?: string; // TODO: it's character of thousands separator
  // decimalSeparator?: string; // TOTO: it's character of decimal separator
  // allowNegative?: boolean; // TODO: it allows minus sign before the number to make it negative
};

export type ToIntegerTextInputValueOptions = CommonOptions & {
  allowLeadingZeros?: boolean;
};

export type ToDecimalTextInputValueOptions = CommonOptions & {
  fixedDigits?: number;
};

/**
 * @example toIntegerTextInputValue('') === ''
 * @example toIntegerTextInputValue('234') === '234'
 * @example toIntegerTextInputValue('1.234') === '1234'
 * @example toIntegerTextInputValue('0.g234.a#sd56f7') === '234567'
 * @example toIntegerTextInputValue('0.234') === '234'
 * @example toIntegerTextInputValue('0234') === '234'
 * @example toIntegerTextInputValue('00.234') === '234'
 * @example toIntegerTextInputValue('0234', { allowLeadingZeros: true }) === '0234'
 * @example toIntegerTextInputValue('0.g234.a#sd56f7', { allowLeadingZeros: true }) === '0234567'
 * @example toIntegerTextInputValue('00.00234', { allowLeadingZeros: true }) === '0000234'
 */
export function toIntegerTextInputValue(
  value?: string,
  { allowLeadingZeros = false }: ToIntegerTextInputValueOptions = {}
): string {
  const replaced = value?.replace(/[^0-9]/g, '') || '';
  if (allowLeadingZeros) return replaced;
  const newValue = Number.parseInt(replaced, 10);
  return isNaN(newValue) ? '' : newValue.toString();
}

/**
 * @example toDecimalTextInputValue('') === ''
 * @example toDecimalTextInputValue('234') === '234'
 * @example toDecimalTextInputValue('.') === '0.'
 * @example toDecimalTextInputValue('0.') === '0.'
 * @example toDecimalTextInputValue('0.234') === '0.234'
 * @example toDecimalTextInputValue('0.g234.a#sd56f7') === '0.234567'
 * @example toDecimalTextInputValue('0234') === '234'
 * @example toDecimalTextInputValue('00.234') === '0.234'
 * @example toDecimalTextInputValue('00.234', { fixedDigits: 2 }) === '0.23'
 */
export function toDecimalTextInputValue(
  value?: string,
  { fixedDigits, ...commonOptions }: ToDecimalTextInputValueOptions = {}
) {
  const splitted = (value?.trim() ?? '').split('.');
  if (splitted.length === 1) {
    return toIntegerTextInputValue(splitted[0]);
  }
  const fixed = toIntegerTextInputValue(splitted.slice(1).join(''), {
    ...commonOptions,
    allowLeadingZeros: true,
  });
  return (
    (toIntegerTextInputValue(splitted[0], commonOptions) || '0') +
    '.' +
    (fixedDigits ? fixed.slice(0, fixedDigits ?? 0) : fixed)
  );
}

/**
 * @description It's a wrapper of Decimal.toFixed() method. It's useful to avoid the big number scientific notation problem.
 **/
export const toFixed = (value: number | string, digits: number = 0) => {
  return new Decimal(value).toFixed(digits);
};
