import { formatDuration, intervalToDuration } from 'date-fns';

/**
 * See:
 * https://github.com/date-fns/date-fns/blob/main/src/locale/en-US/_lib/formatDistance/index.ts
 */
const formatDistanceLocale = {
  xSeconds: 's',
  xMinutes: 'm',
  xHours: 'h',
  xDays: 'd',
  xWeeks: 'w',
  xMonths: 'M',
  xYears: 'y',
};
function shortFormatDistance(
  token: keyof typeof formatDistanceLocale,
  count: number
) {
  return `${count}${formatDistanceLocale[token]}`;
}

type FormatOptions = {
  format?: (keyof Duration)[];
  zero?: boolean;
  delimiter?: string;
  /** format in short-hand mode. e.g. 2h 36m */
  shortFormat?: boolean;
};

/**
 * format an `Interval` into human friendly string
 * @param interval the interval you want to format
 * @param formatOptions
 * @returns a human friendly text representing the date interval
 */
export const formatDateInterval = (
  interval: Interval,
  formatOptions?: FormatOptions
) => {
  const durationObj = intervalToDuration(interval);

  return formatDuration(durationObj, {
    ...formatOptions,
    ...(formatOptions?.shortFormat && {
      locale: {
        formatDistance: shortFormatDistance,
      },
    }),
  });
};
