import { enUS, enGB, es, it } from 'date-fns/locale';
type LocaleCode = 'en-US' | 'en-GB' | 'es' | 'it';
// get the date-fns locale object for the given locale code
export const getLocale = (localeCode: LocaleCode): Locale => {
  return locales[localeCode] || enGB;
};

export const locales = {
  'en-US': enUS,
  'en-GB': enGB,
  es,
  it,
};
