import { accurateCalcDecimalInstance } from '../utils';
import { Decimal } from 'decimal.js';
/**
 * Convert Satoshi Amount to Bitcoin
 * @param satoshiAmount The Amount in Satoshi
 * @returns Amount in Bitcoin
 */
export const convertSatoshiToBitcoin = (
  satoshiAmount: number | string
): number => {
  return Number(
    accurateCalcDecimalInstance(satoshiAmount, '/', 100_000_000).toFixed(
      8,
      Decimal.ROUND_UP
    )
  );
};

/**
 * Convert Bitcoin Amount to Satoshi
 * @param bitcoinAmount The Amount in Bitcoin
 * @returns Amount in Satoshi
 */
export const convertBitcoinToSatoshi = (
  bitcoinAmount: string | number
): number => {
  return accurateCalcDecimalInstance(bitcoinAmount, '*', 100_000_000)
    .trunc()
    .toNumber();
};
