import { isValid, isMatch } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
/**
 * Formats the date into the request format and converts the given date to a
 * certain timezone, if asked.
 *
 * @param date JS Date or ISO-compatible date time string
 * @param dateFormat
 * @param options Code of the timezone, e.g. CEST or Asia/Tehran, or an Options object
 * @returns Formatted date
 */
export const formatDate = (
  date: string | Date,
  dateFormat: DateFormat = 'yyyy-MM-dd',
  // if it's a string, it should be a valid timezone if not it should be an object
  options?: string | { timeZone?: string; locale?: Locale }
): string => {
  if (!date) {
    return '';
  }
  const { timeZone, locale } =
    typeof options === 'string'
      ? { timeZone: options, locale: undefined }
      : options || {};

  const defaultTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const dateInstance = typeof date === 'string' ? new Date(date) : date;
  if (isValid(dateInstance)) {
    return formatInTimeZone(
      dateInstance,
      timeZone ?? defaultTimeZone,
      dateFormat,
      {
        locale,
      }
    );

    // return formatInTimeZone(dateInstance, dateFormat, {
    //   timeZone,
    //   locale,
    // });
  } else {
    throw new Error('an invalid date is given to the `formatDate`.');
  }
};

export const isDateMatch = (date: string, format: DateFormat): boolean =>
  isMatch(date, format);

export type DateFormat =
  | 'yyyy-MM-dd'
  | 'yyyy/MM/dd'
  | 'yyyy/MM/dd HH:mm:ss'
  | 'yyyy-MM-dd HH:mm'
  | 'yyyy-MM-dd HH:mm:ss'
  | 'M/d/yyyy - hh:mm a'
  | 'MMM dd, yyyy - hh:mm a'
  | 'MMM do yy'
  | 'MMMM dd, yyyy'
  | 'd MMMM yyyy'
  // Notice 00:00:00 is hardcoded
  | "yyyy-MM-dd '00:00:00'xxx"
  | 'HH:mm'
  | 'HH:mm:ss'
  | 'MMMM';
