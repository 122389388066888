import { Decimal } from 'decimal.js';

type Operator = '+' | '-' | '*' | '/';

/**
 * Calculate accurately without having problem with IEEE-754
 *
 * A layer of abstraction over "decimal.js" library in order to
 * provide a more suitable API for the project.
 *
 * @param num1 First operand in calculation
 * @param operator Operator of the calculation
 * @param num2 Second operand in calculation
 * @returns Result of the calculation
 */
export const accurateCalc = (
  num1: number,
  operator: Operator,
  num2: number
): number => {
  return accurateCalcDecimalInstance(num1, operator, num2).toNumber();
};

// use this in case you want to access the Decimal class directly otherwise use the accurateCalc function
export const accurateCalcDecimalInstance = (
  num1: Decimal.Value,
  operator: Operator,
  num2: Decimal.Value
): Decimal => {
  //should return 0 if any of the operands is NaN
  if (isNaN(num1 as number) || isNaN(num2 as number)) {
    return new Decimal(0);
  }

  const decimal1 = new Decimal(num1);
  const decimal2 = new Decimal(num2);

  switch (operator) {
    case '+':
      return decimal1.plus(decimal2);
    case '-':
      return decimal1.minus(decimal2);
    case '*':
      return decimal1.times(decimal2);
    case '/':
      return decimal1.div(decimal2);
    default:
      throw new Error('Invalid Operator');
  }
};
